import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from '@reach/router';

import { ContinueAuth } from '../utils/auth0';
import { Login } from '../state/login/thunks';

export const querystring = (obj) => {
  const result = [];
  let match;
  const re = new RegExp('(?:\\?|&)' + obj + '=(.*?)(?=&|$)', 'gi');
  while ((match = re.exec(document.location.search)) !== null) {
    result.push(match[1]);
  }
  return result;
};

const LoginComponent = (props) => {
  const [link, setLink] = useState();

  useEffect(() => {
    if (window === void 0) {
      return null;
    }

    const query = window.location.search;

    if (query.includes('code=') && query.includes('state=')) {
      ContinueAuth()
        .then(async (fullAuth) => {
          const didExsist = await props.login(fullAuth);
          const redirectTo = querystring('return_to')[0];
          if (!didExsist) {
            setLink(`/create?after=${redirectTo}`);
          }
          if (!redirectTo) {
            setLink('/');
            return;
          }
          setLink(redirectTo);
        })
        .catch((e) => {
          setLink('/');
        });
    } else {
      setLink('/');
    }
  }, []);

  if (!!link) {
    return <Redirect noThrow to={decodeURIComponent(link)} />;
  }
  return null;
};

const mapDispatchToProps = (dispatch) => ({
  login: (object) => dispatch(Login(object)),
});

export default connect(null, mapDispatchToProps)(LoginComponent);
